import Vue from "vue";
import {UserEntity, UserRoleEntity, UserTeamEntity} from "@/core/entity/core/user";
import display from "@/configure/display/core/user";
import service from "@/core/service";
import {Verifiable} from "@/core/types/vuetify";
import {CompanyEntity} from "@/core/entity/core/company";

export default Vue.extend({
    components: {},
    data: () => ({
        key: void 0 as undefined | number,
        loading: false,
        readonly: false,
        saving: false,
        data: new UserEntity(),
        display,
        companyList: [] as CompanyEntity[],
        teamList: [] as UserTeamEntity[],
        level: [
            {
                key: 1,
                name: "企业级权限"
            },
            {
                key: 2,
                name: "用户级权限"
            },
        ]
    }),
    async mounted() {
        this.companyList = await service.company.query({});
        this.teamList = await service.userTeam.query({});
        switch (this.$route.name) {
            case "user.add":
                this.data = {
                    userType: 0,
                    status: 1,
                };
                break;
            case "user.load":
                this.readonly = true;
            case "user.edit":
                this.key = Number(this.$route.params.key);
                await this.load();
                break;
        }
    },
    methods: {
        async load() {
            this.loading = true;
            try {
                this.data = await service.user.load(this.key!);
            } finally {
                this.loading = false;
            }
        },
        async save() {
            if ((<Verifiable>this.$refs.form).validate()) {
                this.saving = true;
                try {
                    if (this.key === void 0) {
                        this.data = await service.user.add(this.data);
                        this.key = this.data.key;
                        if (this.key !== void 0) {
                            let userRole: UserRoleEntity = {
                                key: this.key,
                                userKey: this.data.key,
                                roleList: [{
                                    roleKey: 10000
                                }]
                            }
                            await service.userRole.save(userRole);
                        }
                    } else {
                        await service.user.save(this.data);
                        this.data = await service.user.load(this.key);
                    }
                    this.$message("保存成功!", "success");
                } finally {
                    this.saving = false;
                }
            } else
                this.$message("有数据填写错误!", "error");
        }
    }
});
